import React, { useEffect, useState } from "react"
import SEO from "../seo"
import {
  AnyQuestions,
  background,
  Description,
  Highlight,
  HowMuch,
  MainSection,
  SectionModel,
  surface,
  Warranty,
} from "./index"
import { URL_HOTMART_EBOOK_HABITOS } from "../../constants"
import { getQueryParam } from "../../utils/query"
import { getTextCupom, formatToMoney } from "../../utils/cupom"
const ebookHabitos = require("../../assets/images/ebookHabitos.png")

export default function Habitos() {
  const [cupom, setCupom] = useState(null)
  const [utm, setUtm] = useState('')

  useEffect(() => {
    setCupom(getQueryParam("cupom"))
    setUtm(getQueryParam("utm_source"))
  }, [])

  function getUrlCheckout(){
    let url = URL_HOTMART_EBOOK_HABITOS
    if(cupom){
      url = url.concat("?offDiscount=" + cupom)
    }
    if(utm){
      const prefix = url.includes("?") ? "&": "?"
      url = url.concat(prefix+'sck='+utm)
    }
    return url
  }

  function getInfo() {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    const price = 34.7
    return {
      URL_CHECKOUT: getUrlCheckout(),
      oldPrice: "99,70",
      price: formatToMoney(price),
      newPrice: cupom
        ? formatToMoney(price * (1 - discontPercentage / 100))
        : formatToMoney(price),
      textCupom: cupom
        ? getTextCupom(cupom) ||
        (discontPercentage
          ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
          : null)
        : null,
      discontPercentage,
    }
  }
  const {
    URL_CHECKOUT,
    price,
    newPrice,
    oldPrice,
    textCupom,
    discontPercentage,
  } = getInfo()

  const sendEvent = (event)=>{
    if(window){
      if(window.fbq) window.fbq('track', event);             
      if(window.gtag)window.gtag("event", event);
    }
  }

  return (
    <>
      <SEO title="Guia completo sobre Hábitos" pageId="ebook_habitos" />
      <MainSection
        img={ebookHabitos}
        title={<>
        Comece sua <Highlight>jornada de transformação</Highlight> agora mesmo!
        </>}
        description={
          <>
            Não perca mais tempo esperando por mudanças. Aprenda a criar e manter hábitos positivos no longo prazo que te ajudarão a mudar de vida e alcançar seus objetivos.
          </>
        }
        textCupom={textCupom}
      />
      <SectionModel
        id="more"
        background={surface}
        title="Isto é o que acontece quando você NÃO sabe criar hábitos positivos:"
        list={
          <Description style={{ lineHeight: `2rem` }}>
            • Hábitos autodestrutivos que colocam sua saúde em risco fazem parte da sua rotina; <br /><br />
            • Estar sempre cansado e sem disposição é o seu “normal”; <br /><br />
            • É muito raro ter foco para realizar suas tarefas; <br /><br />
            • Você vive num constante modo de procrastinação; <br /><br />
            • É comum sentir que sua vida não está no rumo que você deseja; <br /><br />
            • Não consegue alcançar seus sonhos. <br /><br />
            <br />
            Nós já passamos por isso e sabemos como é ruim se sentir assim.
          </Description>
        }
      />
      <SectionModel
        id="3"
        background={background}
        title="Para quem é o Guia Completo sobre Hábitos?"
        list={
          <Description style={{ lineHeight: `2rem` }}>
            Nós criamos este guia para você que deseja:<br /><br /><br />
            ✔️ Criar e manter bons hábitos no longo prazo;<br /><br />
            ✔️ Driblar a procrastinação; <br /><br />
            ✔️ Ter mais energia e disposição no dia a dia;<br /><br />
            ✔️ Se sentir saudável e feliz com o seu corpo;<br /><br />
            ✔️ Ter mais constância e foco;<br /><br />
            ✔️ Construir uma versão incrível de você mesmo.<br /><br />
          </Description>
        }
      />
      <SectionModel
        id="5"
        background={surface}
        title={"O que você vai receber?"}
        img={ebookHabitos}
        list={
          <Description style={{ lineHeight: `2rem` }}>
            Adquira o guia agora mesmo e tenha acesso a:<br /><br /><br />
            ✔️ 10 táticas incríveis para criar e manter hábitos;<br /><br />
            ✔️ 2 bônus exclusivos em PDF para colocar as dicas em prática;<br /><br />
            ✔️ 6 bônus exclusivos em PDF;<br /><br />
            ✔️ 40+ páginas de conteúdo e bônus.<br /><br />
          </Description>
        }
      />
      <SectionModel
        id="6"
        background={background}
        title={"O Guia Completo sobre Hábitos é um compilado de diversas técnicas e conhecimentos sobre hábitos"}
        list={
          <>
            <Description style={{ marginTop: "3rem" }}>
              Isso significa que, para que você tivesse acesso a todo esse conhecimento, você deveria comprar e ler diversos livros e fazer diversos cursos.<br /><br />

              Ou seja, o preço que você estará pagando por este ebook não é nada comparado ao valor que ele pode te trazer.<br /><br />

              Afinal, coisas como liberdade, saúde e qualidade de vida são coisas que o dinheiro não paga!<br /><br />

              Lembre-se: esse não é só um ebook, é a oportunidade de transformar a sua vida, dominar seus hábitos e alcançar a vida dos seus sonhos.<br /><br />
            </Description>

            <HowMuch
              discontPercentage={discontPercentage}
              oldPrice={oldPrice}
              price={price}
              newPrice={newPrice}
              urlCheckout={URL_CHECKOUT}
              onClick={()=> sendEvent('click_checkout_ebook_habitos')}
            />
          </>
        }
      />

      <Warranty id="7" background={surface} />
      <AnyQuestions id="8" />

    </>
  )
}
